import React from "react";


const Throws = ({ items }) => {
  return (
    <div className="my-2">
      <div className="text-2xl mt-3 leading-tight text-primary text-center">Prev round</div>
      {items.length === 0 && <div className="px-1 py-5 my-5 p-2 h-20 whitespace-nowrap bg-blue-400 text-white font-bold text-center text-2xl rounded-md">No score yet</div>}
        {items.slice(-1).map((item, index) => (
          <div className="px-1 py-5 my-4 p-2 h-20 whitespace-nowrap bg-blue-400 text-white font-bold text-center text-3xl rounded-md" key={index}>
            {item}
          </div>
        ))}

    </div>



  );
};

export default Throws;