import React from "react";
import "./Wrapper.css";

const Wrapper = ({ children }) => {
  return (
    <div className="grid grid-cols-1 gap-1 justify-content-center">
    <div className="wrapper">{children}</div>
    </div>
  )

  
};

export default Wrapper;
