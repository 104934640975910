import React from "react";
import { Textfit } from "react-textfit";
import "./Screen.css";

const Screen = ({ value, Validation, isDone }) => {
  return (
    <div>
      {Validation}

    <Textfit className={`${Validation ? "screen text-red-500 border-solid border-2 border-red-600" : "screen text-white"}`} mode="single">
      {isDone === true ? "The end" : value}
    </Textfit>
    </div>
  );
};

export default Screen;
