import React, { useState } from "react";

import Wrapper from "./components/Wrapper";
import Screen from "./components/Screen";
import ButtonBox from "./components/ButtonBox";
import Button from "./components/Button";
import Throws from "./components/Throws";


import "./App.css";

const btnValues = [
  ["C", 100, 60, 45],
  [7, 8, 9, 140],
  [4, 5, 6, 85],
  [1, 2, 3, 96],
  [0, "Back", "Enter"],
];


const App = () => {

  /*input value of throws*/
  const [ScreenValue, setScreenValue] = useState({
    num: 0,
  });

  /* array of the throws values */
  const [items, SetItems] = useState([]);

  /*validation of input data - is the data wrong? */
  const [Validation, setValidation] = useState(false);

  /* is the practise done? */
  const [isDone, setIsDone] = useState(false);


  // 0-9 button onclick, add to Number
  const numClickHandler = (e) => {
    if (Validation === true) {
      return;
    }
    else {
      e.preventDefault();
      const value = e.target.innerHTML;
      setScreenValue({
        ...ScreenValue,
        num: Number(ScreenValue.num + value),
      });

    }

  };

  // add pre done value to the input
  const addNumberHandler = (e) => {
    e.preventDefault();
    const value = e.target.innerHTML;
    SetItems(items => {
      items.length === 49 ? setIsDone(true) : setIsDone(false);
      return [...items, value]
    })
    resetClickHandler();
  };


  // cancel prev value
  const backClickHandler = (e) => {
    SetItems(existingItems => {
      return existingItems.slice(0, existingItems.length - 1)
    })
  };

  // onClick add the value to the array
  const enterClickHandler = (e) => {
    const value = ScreenValue.num;
    if (value < 0 || value > 180) {
      setValidation(true);
      return;
    }

    SetItems(items => {
      return [...items, value]
    })
    resetClickHandler();
  };

  // reset input value
  const resetClickHandler = (e) => {
    setScreenValue({
      num: 0,
    });
    setValidation(false);
  };


  // add onClick to the button
  const buttonClickHandler = (e, btn) => {
    btn === "C"
      ? resetClickHandler(e)
      : btn === "Back"
        ? backClickHandler(e)
        : btn === "Enter"
          ? enterClickHandler(e)
          : btn > 9
            ? addNumberHandler(e)
            : numClickHandler(e)

  }

  return (
    <div>
      <div className="mb-1 mt-0 text-2xl leading-tight text-primary text-center">Remaining rounds</div>
      <Button value={50 - items.length} className="grey-background text-white p-3 py-1 font-bold disabled w-full rounded-md"></Button>
      <Throws items={items} />
      {isDone ? <Button value={"Show statistics"} className={'px-1 py-3 my-2 p-2 w-100 whitespace-nowrap bg-green-700 text-white font-bold rounded text-center'} onClick={null}/> : null }

      <Wrapper>
        <Screen value={ScreenValue.num} Validation={Validation} isDone={isDone}/>
        <ButtonBox isDone={isDone}>
          {btnValues.flat().map((btn, i) => {
            return (
              <Button
                key={i}
                className={btn !== 'C' && Validation ?
                  'bg-blue-500 text-white font-bold rounded opacity-50 cursor-not-allowed p-2' :
                  btn === "Enter" ? "bg-blue-500 border-1 text-white font-bold rounded p-2" :
                    'bg-blue-500 text-white font-bold rounded p-2'}
                value={btn}
                onClick={(e) => buttonClickHandler(e, btn)}
              />
            );
          })}
        </ButtonBox>
      </Wrapper>

    </div>
    



  );
};

export default App;